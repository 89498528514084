import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import { StaticImage as Img } from 'gatsby-plugin-image'
import About from './style'
export default function AboutSection() {
  return (
    <About backgroundColor="#f2f5fb">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              subTitle="About lono Inc"
              title="Local Anywhere"
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
            <Widget directionXS="row-reverse" title="Real Time Event Feed" icon="fas fa-rss" />
            <Widget directionXS="row-reverse" title="Event Map" icon="fas fa-map-marker-alt" />
            <Widget directionXS="row-reverse" title="Event Calender" icon="far fa-calendar-alt" />
          </Col>
          <Col xs="12" className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center">
            <About.ImageContent>
              <Img src="../../../../assets/image/portfolio/lono/3.png" alt="content" layout="fullWidth" placeholder="blurred" />
              <About.Shape>
                <Img src="../../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
              </About.Shape>
            </About.ImageContent>
          </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
            <Widget directionXS="row" title="Match Event with Passion" icon="fas fa-calendar-check" />
            <Widget directionXS="row" title="Share Experience" icon="fas fa-retweet" />
            <Widget directionXS="row" title="Build Community" icon="fas fa-male" />
          </Col>
        </Row>
      </Container>
    </About>

  )
}